<!--
 * @Author: your name
 * @Date: 2021-03-04 15:20:50
 * @LastEditTime: 2021-04-22 09:54:38
 * @LastEditors: Please set LastEditors
 * @Description: 群发推广
 * @FilePath: \sd-vue-admin\src\views\project\marketing\marketingPush\massPromotion\index.vue
-->
<!--  -->
<template>
  <div class="orderTest-container">
    <el-row type="flex" class="row-bg" justify="end">
      <el-col :span="6">
        <el-button type="primary">新建群发消息</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20">
        <el-table stripe :data="tableData">
          <el-table-column
            v-for="(list, index) in colemd"
            :key="index"
            :label="list.label"
            :align="list.align"
            :prop="list.prop"
            width=""
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="{ row }">
              <el-button type="text" @click="handlerlink(row)">查 看</el-button>
              <el-button type="text" @click="handlerdelete(row)">
                删 除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        url: {
          list: '/shopAdmin/extension/push-list',
        },
        tableData: [],
        colemd: [
          {
            label: '消息主题',
            align: 'center',
            prop: 'title',
            width: '',
          },
          {
            label: '发送时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
          {
            label: '送达客户家数',
            align: 'center',
            prop: 'total_num',
            width: '',
          },
          {
            label: '跳转页面',
            align: 'center',
            prop: 'jump_url',
            width: '',
          },
        ],
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.handlerlist()
    },
    methods: {
      handlerlist() {
        postAction(this.url.list, {})
          .then((res) => {
            console.log(res)
            this.tableData = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
